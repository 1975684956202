/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

/**
 * Package Import
 */
import { WebRTCAdaptor as AntMediaRTCAdaptor } from '@antmedia/webrtc_adaptor';

/**
 * Local Import
 */
import devicesMediasRTC, { getConstraints } from 'src/utils/medias';
import { STREAM_TYPES } from 'src/constants/conference';
import { WebRTCManager, WebRTCManagerInitialProps } from './abstractWebRTCManager';
import { WebRTCEvent } from './webRTCEvent';
import { MediaStreamDictionnary, StreamCategory } from './mediaStreamDictionnary';

/**
 * Types
 */
interface PauseSavingInfosInterface {
  camsAndMicsStreamStarted?: boolean;
}

type StreamId = string;

/**
 * Configuration
 */
const defaultConfig = {
  sdp_constraints: {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true,
  },
  peerconnection_config: {
    iceServers: [
      {
        urls: process.env.ANTMEDIA_TURN_URL,
        credential: process.env.ANTMEDIA_TURN_CREDENTIAL,
        username: process.env.ANTMEDIA_TURN_USERNAME,
      },
    ],
  },
  debug: false,
};

/**
 * Antmedia WebRTC Manager
 */
export class AntMediaWebRTCManager extends WebRTCManager {
  camAndMicAdaptorInitialized = false;

  screenAdaptorInitialized = false;

  camsAndMicRoomJoined = false;

  screenRoomJoined = false;

  camsAndMicsStreamStarted = false;

  screenStreamStarted = false;

  pauseSavingInfos: PauseSavingInfosInterface = {};

  baseMediaConstraints: MediaStreamConstraints = {};

  reloadingStreams: Array<StreamId> = [];

  constructor(props: WebRTCManagerInitialProps) {
    super(props);

    this.addEventListener(WebRTCEvent.WEBRTC_ROOMS_JOINED, () => this.startPolling());
    this.makeAdaptors();
  }

  private makeAdaptors() {
    // get constants
    const WS_URL_CAM = `${this.baseWebSocketURL}/streamCam/websocket`;
    const WS_URL_SCREEN = `${this.baseWebSocketURL}/streamScreen/websocket`;

    // Enable the “Play” mode, in case we don't have a publish stream
    const isPlayMode = !devicesMediasRTC.localStream;

    // WEBCAMS ROOM
    this.baseMediaConstraints = getConstraints();
    this.webRTCCamsAndMicsAdaptor = new AntMediaRTCAdaptor({
      websocket_url: WS_URL_CAM,
      localVideoId: 'localView',
      mediaConstraints: this.baseMediaConstraints,
      ...defaultConfig,
      isPlayMode: isPlayMode ?? false,
      callback: (info: string, obj: any) => {
        if (!['roomInformation', 'pong', 'bitrateMeasurement'].includes(info)) {
          console.log('event:', info, 'data:', obj);
        }

        switch (info) {
          /**
           * WebSocket connection is initialized successfully
           * @event initialized
           */
          case 'initialized':
            this.camAndMicAdaptorInitialized = true;
            if (this.screenAdaptorInitialized) {
              // if the other adaptor is ready too, emit !
              this.emit(WebRTCEvent.WEBRTC_CONNECTED);
            }
            break;

          /**
           * Joined the room
           * @event joinedTheRoom
           */
          case 'joinedTheRoom':
            this.camsAndMicRoomJoined = true;
            this.emit(WebRTCEvent.WEBRTC_CAMS_MICS_ROOM_JOINED);

            // Play all available streams
            this.playCamsStreamList(obj.streams);

            if (this.screenRoomJoined) {
              // if the other room is joined too, emit !
              this.emit(WebRTCEvent.WEBRTC_ROOMS_JOINED);
            }
            break;

          /**
           * Leaved the room
           * @event leavedFromRoom
           */
          case 'leavedFromRoom':
            this.camsAndMicRoomJoined = false;
            this.emit(WebRTCEvent.WEBRTC_CAMS_MICS_ROOM_LEFT);
            if (!this.screenRoomJoined) {
              // if the other room is left too, emit !
              this.emit(WebRTCEvent.WEBRTC_ROOMS_LEFT);
            }

            // Clear streams
            if (this.camsStreamsPlaying !== null) {
              this.camsStreamsPlaying.forEach((streamId) => {
                this.removeCamsStreams(streamId);
              });
            }

            // Reset
            this.resetCamsStreamList();
            break;

          /**
           * Room information
           * @event roomInformation
           */
          case 'roomInformation':
            if (this.webRTCCamsAndMicsAdaptor.mediaManager.localStream || isPlayMode) {
              this.emit(WebRTCEvent.ANTMEDIA_LOCALSTREAM_READY);
            }
            this.playCamsStreamList(obj.streams);
            break;

          /**
           * Stream information
           * @event streamInformation
           */
          case 'streamInformation':
            this.playCamStream(obj.streamId);
            break;

          /**
           * New WebRTC stream available.
           * @event newStreamAvailable
           */
          case 'newStreamAvailable':
            MediaStreamDictionnary.addStream(StreamCategory.CAM, obj.streamId, obj.stream);
            this.emit(WebRTCEvent.NEW_CAM_STREAM_AVAILABLE);
            break;

          /**
           * WebRTC stream publishing has been started
           * @event publish_started
           */
          case 'publish_started':
            // plug an analyser to the local stream if any
            if (this.webRTCCamsAndMicsAdaptor.mediaManager.localStream) {
              MediaStreamDictionnary.addStream(
                StreamCategory.CAM,
                'localView',
                this.webRTCCamsAndMicsAdaptor.mediaManager.localStream,
              );
            }
            this.camsAndMicsStreamStarted = true;
            this.emit(WebRTCEvent.CAM_MIC_STREAM_STARTED);
            break;

          /**
           * WebRTC stream publishing has been finished
           * @event publish_finished
           */
          case 'publish_finished':
            // remove localView stream
            MediaStreamDictionnary.removeStream('localView');
            // unplug the analyser from the local stream
            MediaStreamDictionnary.detachAnalyserNode('localView');
            this.camsAndMicsStreamStarted = false;
            this.emit(WebRTCEvent.CAM_MIC_STREAM_STOPPED);
            break;

          /**
           * WebRTC stream playing has been started
           * @event play_started
           */
          case 'play_started':
            this.emit(WebRTCEvent.CAM_MIC_PLAY_STARTED);
            break;

          /**
           * WebRTC stream playing has been finished
           * @event play_finished
           */
          case 'play_finished':
            this.removeCamsStreams(obj.streamId);

            // Can we reload the stream ?
            if (this.reloadingStreams.includes(obj.streamId)) {
              this.reloadingStreams = this.reloadingStreams.filter(
                (reloadingStreamId) => reloadingStreamId !== obj.streamId,
              );

              this.playCamStream(obj.streamId);
            }
            this.emit(WebRTCEvent.CAM_MIC_STREAM_STOPPED);
            break;
          default:
            break;
        }
      },
      callbackError: (err: string, message: any) => {
        console.error('ERROR IN WEBCAM ADAPTOR', err, message);

        // Throw an error, so an 3rd service, like `Sentry` can caught it
        throw new Error(err);
      },
    });

    // SCREENS ROOM
    this.webRTCScreensAdaptor = new AntMediaRTCAdaptor({
      websocket_url: WS_URL_SCREEN,
      localVideoId: 'screenView',
      mediaConstraints: {
        video: STREAM_TYPES.SCREEN,
        audio: true,
      },
      ...defaultConfig,
      isPlayMode: true,
      callback: (info: string, obj: any) => {
        switch (info) {
          case 'initialized':
            this.screenAdaptorInitialized = true;
            if (this.camAndMicAdaptorInitialized) {
              // if the other adaptor is ready too, emit !
              this.emit(WebRTCEvent.WEBRTC_CONNECTED);
            }
            break;
          case 'joinedTheRoom':
            this.screenRoomJoined = true;
            this.emit(WebRTCEvent.WEBRTC_SCREENS_ROOM_JOINED);
            if (this.camsAndMicRoomJoined) {
              // if the other room is joined too, emit !
              this.emit(WebRTCEvent.WEBRTC_ROOMS_JOINED);
            }
            break;
          case 'leavedFromRoom':
            this.screenRoomJoined = false;
            this.emit(WebRTCEvent.WEBRTC_SCREENS_ROOM_LEFT);
            if (!this.camsAndMicRoomJoined) {
              // if the other room is left too, emit !
              this.emit(WebRTCEvent.WEBRTC_ROOMS_LEFT);
            }
            break;
          case 'roomInformation':
            this.playScreenStream(obj.streams);
            break;
          case 'newStreamAvailable':
            MediaStreamDictionnary.addStream(StreamCategory.SCREEN, obj.streamId, obj.stream);
            this.emit(WebRTCEvent.NEW_SCREEN_STREAM_AVAILABLE);
            break;
          case 'publish_started':
            this.screenStreamStarted = true;
            this.emit(WebRTCEvent.SCREEN_STREAM_STARTED);
            break;
          case 'publish_finished':
            this.screenStreamStarted = false;
            this.emit(WebRTCEvent.SCREEN_STREAM_STOPPED);
            // this to close the browser control popup
            this.webRTCScreensAdaptor.mediaManager.localStream.getVideoTracks()[0].stop();
            break;
          case 'play_finished':
            MediaStreamDictionnary.removeStream(obj.streamId);
            this.emit(WebRTCEvent.SCREEN_STREAM_STOPPED);
            break;
          default:
            break;
        }
      },
      callbackError: (err: string, message: any) => {
        console.error('ERROR IN SCREEN ADAPTOR', err, message);

        // Throw an error, so an 3rd service, like `Sentry` can caught it
        throw new Error(err);
      },
    });
  }

  joinAllRooms(): void {
    this.joinCamsAndMicsRoom();
    this.joinScreensRoom();
  }

  joinCamsAndMicsRoom(): void {
    this.webRTCCamsAndMicsAdaptor.joinRoom(
      this.camsAndMicsRoomIdentifier,
      this.camAndMicStreamIdentifier,
      'legacy',
    );
  }

  joinScreensRoom(): void {
    this.webRTCScreensAdaptor.joinRoom(
      this.screensRoomIdentifier,
      this.screenStreamIdentifier,
      'legacy',
    );
  }

  leaveAllRooms(): void {
    this.leaveCamsAndMicsRoom();
    this.leaveScreensRoom();
  }

  leaveCamsAndMicsRoom(): void {
    this.webRTCCamsAndMicsAdaptor.leaveRoom(this.camsAndMicsRoomIdentifier);
  }

  leaveScreensRoom(): void {
    this.webRTCScreensAdaptor.leaveRoom(this.screensRoomIdentifier);
  }

  playCamStream(streamId: string): void {
    this.webRTCCamsAndMicsAdaptor.play(streamId, null, this.camsAndMicsRoomIdentifier);
  }

  startMyCamAndMicStream({ audioOnly }: { audioOnly: boolean }): void {
    this.webRTCCamsAndMicsAdaptor.mediaConstraints.video = !audioOnly
      ? this.baseMediaConstraints.video
      : false;

    this.webRTCCamsAndMicsAdaptor.isPlayMode = false;
    this.webRTCCamsAndMicsAdaptor.publish(this.camAndMicStreamIdentifier, null);
  }

  stopMyCamAndMicStream(): void {
    this.webRTCCamsAndMicsAdaptor.stop(this.camAndMicStreamIdentifier);
  }

  startMyScreenStream(): void {
    // Because of the AntMedia behaviour, it can be that there is an existing stream
    // but all tracks are stopped and there is no way to restart them !
    // So we "destroy" directly the localStream of the AntMedia adaptor
    // to force it to create a new one
    // The old stream exists but will be destroyed by the garbage collector
    const screenTrack = this.webRTCScreensAdaptor.mediaManager.localStream?.getVideoTracks()[0];
    if (screenTrack?.readyState === 'ended') {
      this.webRTCScreensAdaptor.mediaManager.localStream = null;
    }

    // Antmedia makes strange behaviours such a "always start a stream on a webcam".
    // If we try to start publishing THEN switching to desktop capture,
    // some OS (like OSX 12 Monterey) will prevent the capture from starting
    // as it does not directly comes from a human gesture.
    // SO, we use the native function, get a stream, and pass it to the SDK.
    navigator.mediaDevices
      .getDisplayMedia(this.webRTCScreensAdaptor.mediaConstraints)
      .then((stream) => {
        // add the onended event listener
        stream.getVideoTracks()[0].addEventListener('ended', () => {
          this.stopMyScreenStream();
        });

        this.webRTCScreensAdaptor.isPlayMode = false;
        this.webRTCScreensAdaptor.mediaManager.gotStream(stream);
        this.webRTCScreensAdaptor.publish(this.screenStreamIdentifier, null);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  stopMyScreenStream(): void {
    this.webRTCScreensAdaptor.stop(this.screenStreamIdentifier);
  }

  enableLocalMic(): void {
    this.webRTCCamsAndMicsAdaptor.unmuteLocalMic();
    this.emit(WebRTCEvent.LOCAL_MIC_ENABLED);
  }

  disableLocalMic(): void {
    this.webRTCCamsAndMicsAdaptor.muteLocalMic();
    this.emit(WebRTCEvent.LOCAL_MIC_DISABLED);
  }

  enableLocalVideo(): void {
    // @ TO TEST? if there is no video track, close and recreate the stream ?
    // OR add a video track manually ?
    this.webRTCCamsAndMicsAdaptor.turnOnLocalCamera();
    this.emit(WebRTCEvent.LOCAL_VIDEO_ENABLED);
  }

  disableLocalVideo(): void {
    if (devicesMediasRTC.localStream) {
      const localStream: MediaStream = devicesMediasRTC.localStream;
      localStream.getVideoTracks()[0]?.stop();
    }
    this.webRTCCamsAndMicsAdaptor.turnOffLocalCamera();
    this.emit(WebRTCEvent.LOCAL_VIDEO_DISABLED);
  }

  pause(): void {
    // Save streams state
    this.pauseSavingInfos = {
      camsAndMicsStreamStarted: this.camsAndMicsStreamStarted,
    };

    if (this.camsAndMicsStreamStarted) {
      this.stopMyCamAndMicStream();
    }

    this.leaveScreensRoom();

    // @TODO: Maybe this should be in a callback ?
    this.emit(WebRTCEvent.PAUSE_ENABLED);
  }

  unpause(): void {
    if (this.pauseSavingInfos.camsAndMicsStreamStarted) {
      // @TODO : not false ?
      this.startMyCamAndMicStream({ audioOnly: false });
    }

    this.joinScreensRoom();
    // Reset streams state infos
    this.pauseSavingInfos = {};
    this.emit(WebRTCEvent.PAUSE_DISABLED);
  }

  // Active props
  private camsStreamsPlaying: Array<StreamId> = [];

  private screenStreamsPlaying: Array<StreamId> = [];

  private startPolling(): void {
    setInterval(() => {
      this.webRTCCamsAndMicsAdaptor.getRoomInfo(
        this.camsAndMicsRoomIdentifier,
        this.camAndMicStreamIdentifier,
      );
      this.webRTCScreensAdaptor.getRoomInfo(
        this.screensRoomIdentifier,
        this.screenStreamIdentifier,
      );
    }, 3000);
  }

  private resetCamsStreamList() {
    this.camsStreamsPlaying = [];
  }

  private resetScreenStreamList() {
    this.screenStreamsPlaying = [];
  }

  private playCamsStreamList(streamList: Array<any>) {
    // If the stream doesn't exists, create the stream
    streamList.forEach((stream) => {
      if (!this.camsStreamsPlaying.includes(stream)) {
        this.playCamStream(stream);
      }
    });

    // Clean oldest streams
    this.cleanCamsInMediaDictionnary();

    // Lastly updates the current streamlist with the fetched one.
    this.camsStreamsPlaying = streamList;
  }

  private playScreenStream(streamList: Array<any>) {
    // If the stream doesn't exists, create the stream
    streamList.forEach((stream) => {
      if (!this.screenStreamsPlaying.includes(stream)) {
        this.webRTCScreensAdaptor.play(stream, null, this.screensRoomIdentifier);
      }
      this.screenStreamsPlaying.push(stream);
    });

    // Clean oldest streams
    this.cleanScreensInMediaDictionnary();

    // Lastly updates the current streamlist with the fetched one.
    this.screenStreamsPlaying = streamList;
  }

  private cleanCamsInMediaDictionnary() {
    MediaStreamDictionnary.getAllStreamIds(StreamCategory.CAM).forEach((streamId) => {
      if (!this.camsStreamsPlaying.includes(streamId) && streamId !== 'localView') {
        this.removeCamsStreams(streamId);
      }
    });
  }

  private removeCamsStreams(streamId: string) {
    // Remove from the dictonnary
    MediaStreamDictionnary.removeStream(streamId);

    // Stop the stream
    this.webRTCCamsAndMicsAdaptor.stop(streamId);
    this.camsStreamsPlaying = this.camsStreamsPlaying.filter((item) => item !== streamId);
  }

  private cleanScreensInMediaDictionnary() {
    MediaStreamDictionnary.getAllStreamIds(StreamCategory.SCREEN).forEach((streamId) => {
      if (!this.screenStreamsPlaying.includes(streamId)) {
        MediaStreamDictionnary.removeStream(streamId);
      }
    });
  }
}
