/* eslint-disable max-len */
/**
 * Package Import
 */
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button } from '@oclock/crumble';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

/**
 * Local Import
 */
import EventMessage from 'src/components/Chats/Channel/Chat/Event/container';
import DateSeparator from 'src/components/Chats/Channel/Chat/DateSeparator';
import IntroductionWrapper from 'src/components/Chats/Channel/Chat/MessagesList/IntroductionWrapper';
import Message from 'src/components/Chats/Channel/Chat/Message/container';
import TimeSeparator from 'src/components/Chats/Channel/Chat/TimeSeparator';
import { MessageContext } from 'src/context/Message';
import { UserContext } from 'src/context/User';

// Helpers
import MESSAGES from 'src/constants/messages';
import { getChatAppearance } from 'src/store/selectors/settings';
import { api } from 'src/utils/api';

// Style
import * as S from './style';

const MessagesList = ({ actions, active, chatId, textSize }) => {
  const { user } = useContext(UserContext);

  const containerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const isAtBottomRef = useRef(true);

  const [firstItemIndex, setFirstItemIndex] = useState(MESSAGES.START_OFFSET);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [status, setStatus] = useState('idle');

  const { addMoreMessages, getMessages, noMoreMessages, setNoMoreMessages, visibilityFilter } = useContext(MessageContext);
  const chatAppearance = useSelector(getChatAppearance);

  const { messages, messagesWithDates } = getMessages(chatId, chatAppearance);

  /**
   * scrollToBottom
   * @return {void}
   */
  const scrollToBottom = useCallback(() => {
    containerRef.current.scrollToIndex({
      index: messagesWithDates.length - 1,
      align: 'end',
    });
  }, [messages, messagesWithDates]);

  /**
   * setUnread
   * @param {Number} unread
   * @return {void}
   */
  const setUnread = useCallback(
    (unread) => {
      actions.setChannelUnread({
        chatId,
        unread: Number(unread),
      });
    },
    [actions, chatId],
  );

  /**
   * Load next messages depending on scroll direction
   * @param {'up' | 'down'} direction
   * @returns {void}
   */
  const loadMoreMessages = useCallback(async () => {
    if (visibilityFilter !== MESSAGES.SHOW_ALL) return;

    if (!noMoreMessages) {
      setStatus('pending');

      const moreMessages = await api({
        method: 'GET',
        url: '/messages/moreFromId',
        params: {
          chatId,
          firstMessageId: messages[0].id,
          limit: MESSAGES.STEP_MESSAGES,
        },
      });

      if (moreMessages.data.ids.length > 0) {
        addMoreMessages(moreMessages.data);

        const nextFirstItemIndex = firstItemIndex - MESSAGES.STEP_MESSAGES;
        setFirstItemIndex(nextFirstItemIndex);
      }
      else {
        setNoMoreMessages(true);
      }

      setStatus('idle');
    }
  }, [
    addMoreMessages,
    firstItemIndex,
    messages,
    messagesWithDates,
    setFirstItemIndex,
    setStatus,
    visibilityFilter,
  ]);
  // }, [messages, storedMessages]);

  /**
   * Déclenchée quand l'utilisateur scroll plus haut / revient en bas du chat
   * @param {boolean} isStickyBottom
   */
  const onBottomStateChange = useCallback(
    (isReached) => {
      isAtBottomRef.current = isReached;

      if (isReached) {
        if (hasNewMessage) {
          setUnread(0);
          setHasNewMessage(false);
        }
      }
    },
    [hasNewMessage, setHasNewMessage, setUnread],
  );

  /**
   * Déclenchée quand il y a un changement de réaction sur un message
   * @param {number} messageId
   * @param {Object} reaction
   */
  const onMessageReactionChanged = useCallback(
    ({ list, messageId }) => {
      const lastMessageWithDates = messagesWithDates[messagesWithDates.length - 1];

      if (list.length && lastMessageWithDates && messageId === lastMessageWithDates.id) {
        scrollToBottom();
      }
    },
    [messagesWithDates, scrollToBottom],
  );

  const onStartEndReached = useCallback(() => {
    loadMoreMessages();
  }, [loadMoreMessages, firstItemIndex, visibilityFilter]);

  /**
   * On mount, set unread messages at 0
   */
  useEffect(() => {
    setUnread(0);
  }, []);

  /**
   * When message list updates
   * Check last message against previous last message
   * Handles scrollToBottom on self message
   * Display "new message" banner when not scrolled at bottom and receive message from other user
   */
  useEffect(() => {
    const previousLastMessage = lastMessageRef.current;
    const newLastMessage = messages[messages.length - 1];

    if (
      active
      && previousLastMessage
      && newLastMessage
      && previousLastMessage.id !== newLastMessage.id
    ) {
      if (isAtBottomRef.current) return;

      if (newLastMessage.userId !== user.id) {
        setHasNewMessage(true);
      }
      else {
        scrollToBottom();
      }
    }

    if (newLastMessage) {
      lastMessageRef.current = newLastMessage;
    }
  }, [messages]);

  /**
   * Scroll back to bottom when removing all filters
   */
  useEffect(() => {
    if (visibilityFilter === MESSAGES.SHOW_ALL) {
      scrollToBottom();
    }
  }, [visibilityFilter]);

  return (
    <S.Messages textSize={textSize}>
      {/** Loading history */}
      {status === 'pending' && <S.History>Chargement de l’historique...</S.History>}

      {messagesWithDates.length === 0 && (
        <IntroductionWrapper key="intro" visibilityFilter={visibilityFilter} />
      )}

      {/* Chat as virtualized list */}
      <Virtuoso
        atBottomStateChange={onBottomStateChange}
        components={{ Scroller: S.MessagesScroller }}
        data={messagesWithDates}
        followOutput
        initialTopMostItemIndex={messagesWithDates.length}
        ref={containerRef}
        startReached={onStartEndReached}
        endReached={onStartEndReached}
        itemContent={(index, message) => {
          const messageIndex = messagesWithDates.findIndex((msg) => msg.id === message.id);
          const previousIndex = messageIndex - 1;
          const isPreviousMessageDate = messagesWithDates[previousIndex]?.type === 'message.date';
          // Previous message at index - 1, if previous is date we take index - 2
          const previousMessage = messagesWithDates[previousIndex - Number(isPreviousMessageDate)] ?? undefined;

          if (['channel.event', 'system'].includes(message.type) || message.kind === 'survey') {
            return (
              <EventMessage
                chatAppearance={chatAppearance}
                key={`key-event-${message.id}`}
                message={message}
              />
            );
          }

          if (message.time && message.type === 'message.time' && chatAppearance === 'compact') {
            return <TimeSeparator time={message.time} />;
          }

          if (message.type === 'message.date') {
            return <DateSeparator date={message.date} key={`key-date-${message.date}`} />;
          }

          return (
            <Message
              chatAppearance={chatAppearance}
              key={`key-message-${message.id}`}
              messageId={message.id}
              onReactionChanged={onMessageReactionChanged}
              previousId={previousMessage?.id}
              message={message}
              previousMessage={previousMessage}
            />
          );
        }}
      />

      {/** Return to bottom button */}
      {visibilityFilter === MESSAGES.SHOW_ALL && hasNewMessage && (
        <S.MessagesPending>
          <S.Pending>
            <Button type="button" variant="primary" icon="ArrowDown" onClick={scrollToBottom}>
              Nouveaux messages
            </Button>
          </S.Pending>
        </S.MessagesPending>
      )}
    </S.Messages>
  );
};

/*
 * PropTypes
 */
MessagesList.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** The active chat and the chatID are the same */
  active: PropTypes.bool,
  /** ID of the current chat */
  chatId: PropTypes.string.isRequired,
  /** Size (* font @ #root) of the text */
  textSize: PropTypes.number.isRequired,
  /** For spécial message introduction */
  isPrivate: PropTypes.bool,
};

MessagesList.defaultProps = {
  active: false,
  isPrivate: false,
};

export default memo(MessagesList);
