/*
 * Package Import
 */
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { Card, CourseCard, NoCourseCard } from '@oclock/crumble';
import { ErrorBoundary } from '@sentry/react';
import { useLocation } from 'react-router-dom';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import { isAfter } from 'src/utils/time';
import { UserContext } from 'src/context/User';

// Hooks
import { useFetch } from 'src/hooks/useFetch';

// Constants
import { DASHBOARD_TITLE } from 'src/constants/titles';
import { API_FIND_LIMITATIONS } from 'src/constants/apiRoutes';
import { LIMITATIONS_FEATURES } from 'src/constants/limitations';
import { COURSE_JOIN_MESSAGE } from 'src/constants/dashboard';

// Utils
import { trackLinks, trackEvent } from 'src/utils/tracking';
import {
  getMaxParticipantsReached,
  getCourseJoinDescription,
  getCourseHoursJoiningStatus,
  getCourseRole,
  prepareCourses,
} from './utils';

// Style
import * as S from './style';

/*
 * Components CoursesBoundary & CourseBoundary
 */
const CoursesBoundary = () => (
  <div>
    Une erreur est survenue lors de la récupération des données. Veuillez réessayer dans quelques
    instant.
  </div>
);
const CourseBoundary = () => (
  <Card>
    <div className="crumble-course-card">
      <div className="crumble-course-card-content">
        Une erreur est survenue lors de la récupération des données d&apos;un cours.
      </div>
    </div>
  </Card>
);

/*
 * Component
 */
const CoursesList = () => {
  /*
   * Context
   */
  const { user } = useContext(UserContext);

  /*
   * Hooks
   */
  const { pathname } = useLocation();
  const {
    loading: coursesLoading,
    data: coursesData,
    error: coursesError,
  } = useFetch('courses/today');
  const {
    loading: limitationsLoadging,
    data: limitationsData,
    // error: limitationsError,
  } = useFetch(API_FIND_LIMITATIONS);

  /*
   * Life cycle
   */
  useEffect(() => {
    if (Array.isArray(coursesData) && coursesData.length) {
      // Tracking all join courses buttons
      coursesData.forEach((course) => {
        trackLinks(`a[href="/classroom/${course.id}"]`, 'Course Joined', {
          courseName: course.title,
          courseId: course.id,
          userStatus: getCourseRole(course, user),
          promotionName: course.promotion.name,
        });
      });
    }
  }, [coursesData, user]);

  /*
   * Max participants limitation
   */
  const maxParticipantsLimitation = useMemo(() => {
    if (!limitationsLoadging && Array.isArray(limitationsData) && limitationsData.length) {
      return (
        limitationsData
        && limitationsData.find(
          (limitation) => limitation.feature === LIMITATIONS_FEATURES.MAX_PARTICIPANTS_IN_COURSE,
        )
      );
    }
    return null;
  }, [limitationsData]);

  /**
   * Current courses list
   */
  const todayCourses = useMemo(() => {
    if (!coursesLoading && Array.isArray(coursesData) && coursesData.length && user) {
      const filteredCourses = coursesData.filter(
        (course) => !isAfter(new Date(course.date), new Date()),
      );
      const preparedCourses = prepareCourses(filteredCourses, user);
      return preparedCourses;
    }

    return [];
  }, [coursesData, user]);

  /**
   * Next courses list
   */
  const comingCourses = useMemo(() => {
    if (!coursesLoading && Array.isArray(coursesData) && coursesData.length && user) {
      const filteredCourses = coursesData.filter((course) =>
        isAfter(new Date(course.date), new Date()),
      );
      const preparedCourses = prepareCourses(filteredCourses, user, true);
      return preparedCourses;
    }

    return [];
  }, [coursesData, user]);

  const [coursesHoursStatus, setCoursesHoursStatus] = useState([]);

  const checkCoursesHoursLimitation = useCallback(() => {
    if (todayCourses.length) {
      const currentDate = new Date();
      setCoursesHoursStatus(
        todayCourses.map((course) => getCourseHoursJoiningStatus(currentDate, course.timeslots)),
      );
    }
  }, [todayCourses]);

  /**
   * LifeCycles
   */
  useEffect(() => {
    document.title = DASHBOARD_TITLE;
    // Mixpanel tracking event
    trackEvent('Page Viewed', { title: DASHBOARD_TITLE, url: pathname });
  }, []);

  useEffect(() => {
    const timeOut = setInterval(checkCoursesHoursLimitation, 60000);
    checkCoursesHoursLimitation();

    return () => {
      clearInterval(timeOut);
    };
  }, [checkCoursesHoursLimitation]);

  /*
   * Render
   */
  return user ? (
    <S.Center role="main">
      <S.TitleContainer>
        <S.Title>Liste des cours</S.Title>
      </S.TitleContainer>

      {/* Loading */}
      {coursesLoading && (
        <S.Loading>
          <Loader />
          &nbsp;&nbsp; En cours de chargement...
        </S.Loading>
      )}

      {/* Error */}
      {coursesError?.response?.data?.error && <CoursesBoundary />}

      {/* Courses */}
      <ErrorBoundary fallback={CoursesBoundary}>
        <S.CoursesList>
          <S.CardsContainer>
            {/* Current courses */}
            <S.Category>
              <S.CategoryTitle>Aujourd’hui</S.CategoryTitle>
              {!coursesLoading
                && !!todayCourses.length
                && todayCourses.map((course, index) => {
                  if (!course) return null;

                  // Participants props
                  const currentParticipants = course.connectedUsersIds.length;
                  const maxParticipantsQuantity = maxParticipantsLimitation?.quantity;

                  // Check if the course has reached the max participants
                  const isMaxParticipantsLimitReached = getMaxParticipantsReached({
                    participants: currentParticipants,
                    maxParticipants: maxParticipantsQuantity,
                  });

                  return (
                    <ErrorBoundary fallback={CourseBoundary}>
                      <CourseCard
                        teacher={course.teacher}
                        helpers={course.helpers}
                        course={course}
                        promotion={course.promotion}
                        courseUrl={{ to: `/classroom/${course.id}` }}
                        courseRole={course.courseRole}
                        connectedUsersNumber={
                          course.connectedUsersIds?.length >= 0
                            ? course.connectedUsersIds.length
                            : 0
                        }
                        joinDisabled={isMaxParticipantsLimitReached || !!coursesHoursStatus[index]}
                        joinTooltip={getCourseJoinDescription({
                          isMaxParticipantsLimitReached,
                          courseHoursStatus: coursesHoursStatus[index],
                        })}
                        maxParticipants={maxParticipantsQuantity}
                        key={course.id}
                      />
                    </ErrorBoundary>
                  );
                })}
              {!coursesLoading && !todayCourses.length && <NoCourseCard variant="today" />}
            </S.Category>

            {/* Next courses */}
            <S.Category>
              <S.CategoryTitle>À venir</S.CategoryTitle>
              {!coursesLoading
                && !!comingCourses.length
                && comingCourses.map((course) => {
                  if (!course) return null;

                  return (
                    <ErrorBoundary fallback={CourseBoundary}>
                      <CourseCard
                        teacher={course.teacher}
                        helpers={course.helpers}
                        course={course}
                        promotion={course.promotion}
                        courseUrl={{ to: `/classroom/${course.id}` }}
                        courseRole={course.courseRole}
                        connectedUsersNumber={
                          course.connectedUsersIds?.length >= 0
                            ? course.connectedUsersIds.length
                            : 0
                        }
                        joinDisabled
                        joinTooltip={COURSE_JOIN_MESSAGE.before}
                        key={course.id}
                      />
                    </ErrorBoundary>
                  );
                })}
              {!coursesLoading && !comingCourses.length && <NoCourseCard variant="coming" />}
            </S.Category>
          </S.CardsContainer>
        </S.CoursesList>
      </ErrorBoundary>
    </S.Center>
  ) : null;
};

/*
 * Export
 */
export default CoursesList;
