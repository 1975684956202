/*
 * Package Import
 */
import React, { useState } from 'react';
import { MenuItem } from '@szhsin/react-menu';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { Emoji } from 'src/components/Format/Formatizer';

// Helpers
import { NO_EMOTION, emotions } from 'src/constants/emotions';
import { isEnter, isSpace } from 'src/utils/events';

// Style
import * as S from './style';

/*
 * Component
 */
const Emotions = ({ actions, user, emotion }) => {
  /*
   * State
   */
  const [selectedEmotion, setSelectedEmotion] = useState(
    /** @type {String} */
    emotion !== NO_EMOTION ? emotions.find((item) => item.emoji === emotion) : null,
  );

  /*
   * Handlers
   */
  const changeEmotion = (emoji) => () => {
    // Send to WS
    if (emotion !== emoji) {
      actions.changeEmotion({ emotion: emoji, user });
    }

    // Send to state
    const newEmotion = emotions.find((item) => item.emoji === emoji);
    setSelectedEmotion(newEmotion);
  };

  const changeEmotionKeyDown = (emoji) => (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      changeEmotion(emoji)();
    }
  };

  /*
   * Render
   */
  return (
    <S.Emotions id="emotion">
      {selectedEmotion ? (
        <S.SelectLabel id="emotion--selected">
          <S.EmojiContainer>
            <Emoji size={16}>{selectedEmotion.emoji}</Emoji>
          </S.EmojiContainer>
          <S.Label>{selectedEmotion.feeling}</S.Label>
          <S.Close
            type="button"
            tabIndex="0"
            aria-label="Supprimer l'émotion"
            onClick={changeEmotion(NO_EMOTION)}
          >
            <S.CloseX />
          </S.Close>
        </S.SelectLabel>
      ) : (
        <S.OptionContainer
          direction="bottom"
          menuButton={({ open }) => (
            <S.SelectLabel
              as="button"
              type="button"
              id="emotion--none"
              tabIndex="0"
              aria-expanded={open}
              aria-haspopup="true"
            >
              <S.Add />
              <S.Label>Ajouter une émotion</S.Label>
            </S.SelectLabel>
          )}
        >
          {emotions.map((item) => (
            <MenuItem
              onClick={changeEmotion(item.emoji)}
              onKeyDown={changeEmotionKeyDown(item.emoji)}
              key={`key-emotion-${item.id}`}
            >
              <S.EmojiContainer>
                <Emoji size={16}>{item.emoji}</Emoji> &nbsp;
              </S.EmojiContainer>
              {item.feeling}
            </MenuItem>
          ))}
        </S.OptionContainer>
      )}
    </S.Emotions>
  );
};

/*
 * PropTypes
 */
Emotions.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  user: PropTypes.string.isRequired,
  emotion: PropTypes.string,
};

Emotions.defaultProps = {
  emotion: NO_EMOTION,
};

/*
 * Export
 */
export default Emotions;
