import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import jwtDecode from 'jwt-decode';
import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Loader from 'src/components/Loader';

// Local import
import { getKcToken } from 'src/store/selectors/kcToken';
import { initializeKcToken } from 'src/store/actions';
import SlippersApi from './SlippersApi';
import { UserProvider } from '../User';

// Helpers

/**
 * Full page loader component
 */
const fullPageLoader = <Loader type="Oval" overlay size={48} />;

/**
 * KeycloakProvider
 * Handles SSO authentication through Keycloak
 */
const KeycloakProvider = ({ keycloakInstance, children }) => {
  const programRefreshTokenTimeoutRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const identity = useSelector(getKcToken);
  const dispatch = useDispatch();

  const [hasBeenLogged, setHasBeenLogged] = useState(false);

  const isUserLogged = identity !== null;

  /**
   * Handles token refresh by updating them every minute
   */
  const programRefreshToken = useCallback(
    (tokens) => {
      if (tokens.token) {
        const decoded = jwtDecode(tokens.token);
        const delay = decoded.exp * 1000 - new Date().getTime() - 10000;

        if (programRefreshTokenTimeoutRef.current) {
          clearTimeout(programRefreshTokenTimeoutRef.current);
        }

        programRefreshTokenTimeoutRef.current = setTimeout(() => {
          keycloakInstance
            .updateToken(60)
            .then((refreshed) => {
              if (refreshed) {
                const newTokens = {
                  token: keycloakInstance.token,
                  refreshToken: keycloakInstance.refreshToken,
                  idToken: keycloakInstance.idToken,
                };

                programRefreshToken(newTokens);
              }
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.error('Une erreur est survenue durant le maintien de la session');
              // Si on a une erreur et que le token est expiré
              // Il faut tenter de le refresh autant qu'on peut
              setTimeout(() => {
                programRefreshToken(tokens);
              }, 3000);
            });
        }, delay);
      }
    },
    [keycloakInstance],
  );

  /**
   * Keycloak event handler
   * @param {AuthClientEvent} event
   */
  const onEvent = (event) => {
    switch (event) {
      case 'onReady': {
        if (!keycloakInstance.authenticated) {
          keycloakInstance.login();
        }

        break;
      }
      case 'onAuthError': {
        break;
      }
      default:
        break;
    }
  };

  /**
   * Relog with token
   * @param {string} token
   */
  const relogWithToken = (token) => {
    if (token) {
      SlippersApi.setKeycloakInstance(keycloakInstance);
      try {
        dispatch(initializeKcToken(token));
        if (!isReady) setIsReady(true);
      }
      catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  /**
   * Keycloak tokens handler
   * @param {AuthClientTokens} tokens
   */
  const onTokens = (tokens) => {
    // If we have been logged in and all tokens are now undefined,
    // we suppose that the user's session has expired, so we log him out.
    if (hasBeenLogged && Object.values(tokens).every((token) => token === undefined)) {
      keycloakInstance.logout();
    }
    relogWithToken(tokens.token);
  };

  /**
   * Handling hasBeenLoggedStatus & logging out if necessary
   */
  useEffect(() => {
    if (hasBeenLogged && !identity) {
      keycloakInstance.logout();
    }
  }, [keycloakInstance, hasBeenLogged, identity]);

  useEffect(() => {
    if (identity) {
      setHasBeenLogged(true);
    }
  }, [identity]);

  return (
    <ReactKeycloakProvider authClient={keycloakInstance} onEvent={onEvent} onTokens={onTokens}>
      {isReady && isUserLogged && keycloakInstance?.authenticated ? (
        <UserProvider>{children}</UserProvider>
      ) : (
        fullPageLoader
      )}
    </ReactKeycloakProvider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
  keycloakInstance: PropTypes.instanceOf(Keycloak).isRequired,
};

export default KeycloakProvider;
