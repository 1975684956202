/**
 * Package Import
 */
import type { DeltaOperation } from 'quill';

/*
 * Local import
 */
import { getStorage } from 'src/utils/storage';

/**
 * Play a sound
 */
export const play = () => {
  const notificationsSettings = getStorage('middleware_settings_notifications');
  if (!notificationsSettings || notificationsSettings?.soundEnabled) {
    const audio = new Audio('/static/media/audios/notification/default.m4a');
    audio.play();
  }
};

/**
 * Check if one of ops in content contain mention & target user
 */
export const isMentionUser = (content: { ops: DeltaOperation[] }, userId: string) =>
  content.ops.some((op) => op.insert?.mention?.id === userId);
