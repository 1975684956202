/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.background._2,
  padding: '0 2rem 4rem 2rem',
  height: 'calc(100vh - 7rem)',
  overflow: 'scroll',
  maxWidth: '100%',
  width: '100%',
}));
export const ErrorTitle = styled.h3(({ theme }) => ({
  color: theme.text._1,
  fontSize: '1.25rem',
  fontWeight: 'bold',
  lineHeight: '150%',
}));
export const ErrorSubtitle = styled.p(({ theme }) => ({
  color: theme.text._1,
  fontSize: '1rem',
  lineHeight: '150%',
}));
