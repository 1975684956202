/**
 * Local Import
 */
import { isAfter, isEqual } from 'src/utils/time';
import {
  COURSE_ROLE,
  COURSE_JOIN_MESSAGE,
  COURSE_JOIN_STATUS,
  EXTENDED_TIMESLOT_TIME,
} from 'src/constants/dashboard';

/**
 * Get course role
 * @param {Object} course
 * @param {Object} client
 * @returns {String}
 */
export const getCourseRole = (course, client) => {
  const isUserInCourseHelpers = course?.helpers.find((helper) => helper._id === client?.id);

  if (course?.teacher._id === client?.id) {
    return COURSE_ROLE.TEACHER;
  }

  if (isUserInCourseHelpers) {
    return COURSE_ROLE.HELPER;
  }

  if (client?.role !== 'basic' && !client?.promosIds?.includes(course.promotion._id)) {
    return COURSE_ROLE.GHOST;
  }

  return COURSE_ROLE.STUDENT;
};

/**
 * Format courses
 * @param {Objects Array} courses
 * @param {Object} user
 * @returns {Objects Array} courses with formatted date, course role and status
 */
export const formatCourses = (courses, user) =>
  courses.map((course) => ({
    ...course,
    date: new Date(course.date),
    courseRole: getCourseRole(course, user),
    timeslots: course.timeslots?.map((timeslot) => ({
      ...timeslot,
      start: new Date(timeslot.start),
      end: new Date(timeslot.end),
    })),
  }));

/**
 * Get role score for sorting
 * @param {Objects Array} coursesRoleCategory
 * @returns {Number} score used for roles sorting
 */
const getRoleScore = (coursesRoleCategory) => {
  const { courseRole } = coursesRoleCategory[0];

  if (courseRole === COURSE_ROLE.TEACHER) {
    return 1;
  }

  if (courseRole === COURSE_ROLE.HELPER) {
    return 2;
  }

  return 3;
};

/**
 * Group courses by role
 * @param {Objects Array} formattedCourses (needs course role)
 * @returns Array of Objects Array
 */
const getCoursesByRole = (formattedCourses) =>
  // divide all courses by course role
  formattedCourses.reduce((coursesByRole, formattedCourse) => {
    // check if this course is already sorted
    const isAlreadySorted = coursesByRole.find((roleCategory) =>
      roleCategory.find((course) => course.id === formattedCourse.id),
    );

    if (!isAlreadySorted) {
      // get role group corresponding to the right role
      const roleCategoryTarget = coursesByRole.find(
        (roleCategory) => roleCategory[0].courseRole === formattedCourse.courseRole,
      );

      if (!roleCategoryTarget) {
        // if role group have not been caught yet
        // create a new array for role group and push this course into it
        coursesByRole.push([formattedCourse]);
      }
      else {
        // else, push this course into the right role group
        roleCategoryTarget.push(formattedCourse);
      }
    }

    return coursesByRole;
  }, []);

/**
 * Group courses by date
 * @param {Objects Array} courses
 * @returns Array of Objects Array
 */
const getCoursesByDate = (courses) =>
  // divide all courses by date
  courses.reduce((coursesByDate, course) => {
    // check if this course is already sorted
    const isAlreadySorted = coursesByDate.find((dateGroup) =>
      dateGroup.find((courseInDateGroup) => courseInDateGroup.id === course.id),
    );

    if (!isAlreadySorted) {
      // get date group corresponding to the right date
      const dateGroupTarget = coursesByDate.find((dateGroup) =>
        isEqual(new Date(dateGroup[0].date), new Date(course.date)),
      );

      if (!dateGroupTarget) {
        // if date group have not been caught yet
        // create a new array for date group and push this course into it
        coursesByDate.push([course]);
      }
      else {
        // else, push this course into the right date group
        dateGroupTarget.push(course);
      }
    }

    return coursesByDate;
  }, []);

/**
 * Sort courses by role
 * @param {Array of Objects Array} coursesByRole
 * @returns {Array of Objects Array}
 * Sorted as :
 * - 1st : teacher role
 * - 2nd : helper role
 * - 3rd : ghost role
 * OR just one for student role
 */
const sortCoursesByRole = (coursesByRole) =>
  coursesByRole.sort(
    (roleCategoryA, roleCategoryB) => getRoleScore(roleCategoryA) - getRoleScore(roleCategoryB),
  );

/**
 * Sort courses by date
 * @param {Array of Objects Array} coursesByDate
 * @returns {Array of Objects Array}
 * Sorted from earliest to latest
 */
const sortCoursesByDate = (coursesByDate) =>
  coursesByDate.sort((dateGroupA, dateGroupB) => {
    const dateA = dateGroupA[0].date;
    const dateB = dateGroupB[0].date;

    return isAfter(dateA, dateB) ? 1 : -1;
  });

/**
 * Sort courses by time
 * @param {Objects Array} courses
 * @returns {Objects Array}
 */
const sortCoursesByTimeslotsStart = (alphasortedByRole) =>
  alphasortedByRole.map((roleCategory) =>
    roleCategory.sort((courseA, courseB) => {
      const timeslotStartA = courseA.timeslots[0]?.start;
      const timeslotStartB = courseB.timeslots[0]?.start;

      return timeslotStartA - timeslotStartB;
    }),
  );

/**
 * Alphasort courses
 * @param {Array of Objects Array} sortedCoursesByRole
 * @returns {Array of Objects Array}
 * alphasorted courses sorted also by role
 */
const alphasortByRole = (sortedCoursesByRole) =>
  sortedCoursesByRole.map((roleCategory) =>
    roleCategory.sort((courseA, courseB) =>
      (courseB.title.toLowerCase() > courseA.title.toLowerCase() ? -1 : 1),
    ),
  );

/**
 * Prepare courses for courses list
 * @param {Objects Array} courses
 * @param {Object} user
 * @param isComing
 * @returns {Objects Array}
 * courses formatted and sorted this way :
 * - for today courses :
 * prepared courses[
 *  sorted courses by role[
 *   teacher/helper/ghost/student role[
 *    alphasorted courses
 *   ]
 *  ]
 * ]
 * - for coming courses :
 * prepared courses[
 *  sorted courses by date[
 *   sorted courses by role[
 *    teacher/helper/ghost/student role[
 *     alphasorted courses
 *    ]
 *   ]
 *  ]
 * ]
 * The whole is flattened.
 */
export const prepareCourses = (courses, user, isComing = false) => {
  // Format courses
  const formattedCourses = formatCourses(courses, user);

  // Divide formatted courses by role
  const coursesByRole = getCoursesByRole(formattedCourses);

  // Sort divided courses by role
  const sortedCoursesByRole = sortCoursesByRole(coursesByRole);

  // Alphasort courses for each role group
  const alphasortedCoursesByRole = alphasortByRole(sortedCoursesByRole);

  // Sort by timeslot start for each role group
  const sortedCoursesByTimeslotsStart = sortCoursesByTimeslotsStart(alphasortedCoursesByRole);

  if (isComing) {
    // If it's coming courses, divide flatenned alphasorted courses by date
    const coursesByDate = getCoursesByDate(sortedCoursesByTimeslotsStart.flat());

    // Sort divided courses by date
    const sortedCoursesByDate = sortCoursesByDate(coursesByDate);

    // Return flatenned courses by date
    return sortedCoursesByDate.flat();
  }

  // If it's today courses, return flatenned sorted courses
  return sortedCoursesByTimeslotsStart.flat();
};

// Check if datetime is between at least one timeslot
export const isInTimeslots = (datetime, timeslots) =>
  timeslots.some(
    (timeslot) =>
      datetime.getTime() >= timeslot.start.getTime() - EXTENDED_TIMESLOT_TIME
      && datetime.getTime() <= timeslot.end.getTime() + EXTENDED_TIMESLOT_TIME,
  );

export const getCourseHoursJoiningStatus = (datetime, timeslots) => {
  try {
    if (datetime.getTime() < timeslots[0].start.getTime() - EXTENDED_TIMESLOT_TIME) {
      return COURSE_JOIN_STATUS.BEFORE;
    }

    if (
      datetime.getTime()
      > timeslots[timeslots.length - 1].end.getTime() + EXTENDED_TIMESLOT_TIME
    ) {
      return COURSE_JOIN_STATUS.AFTER;
    }

    if (!isInTimeslots(datetime, timeslots)) {
      return COURSE_JOIN_STATUS.BETWEEN;
    }
  }
  catch (error) {
    return false;
  }
  return false;
};

/**
 * Has the maximum number of participants been reached?
 */
export const getMaxParticipantsReached = ({ participants, maxParticipants }) => {
  if (!maxParticipants) {
    return false;
  }

  return participants >= maxParticipants;
};

/**
 * Get the joining description of a course
 */
export const getCourseJoinDescription = ({ isMaxParticipantsLimitReached, courseHoursStatus }) => {
  if (isMaxParticipantsLimitReached) {
    return COURSE_JOIN_MESSAGE[COURSE_JOIN_STATUS.MAX_PARTICIPANTS_LIMIT_REACHED];
  }

  if (courseHoursStatus) {
    return COURSE_JOIN_MESSAGE[courseHoursStatus];
  }

  return false;
};
